/**
 * GLOBAL OVERRIDES
 */
// disable scrolling thus hiding all page content
// when choose path section is showing
body:has(#root .ChoosePath) {
  overflow: hidden;

  // if animation is playing make sure navbar is
  // fixed at top of screen. if user scrolled before
  // reloading they may not be positioned at top of screen
  // so we want to force navbar to be at top while animation plays
  .NavbarCustom {
    position: fixed;
  }
}

.Home {

  .HomeHero {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("../../../assets/images/HomePage.png");
    background-size: cover;
    background-position: center;
  }

  .ChoosePathHero {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("../../../assets/images/Yasu_final_4k.jpg");
    background-size: cover;
    background-position: center;
  }

  .YasuLibMainContent {
    background-image: url("../../../assets/images/ColourBlurBackground.png");
    background-size: cover;
    background-position: center;
    // Uncomment this if you want the blur to follow (looks kinda cool but not in figma)
    //background-attachment: fixed;
    // margin-top: -50px;
    padding-top: 50px;
  }
}