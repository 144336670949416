.Disclaimer{
    .CustomRow div {
        position: relative; /* Make the list item position relative */
    }
    
    .ListNumber {
        position: absolute; /* Make the number position absolute */
        left: -8px; /* Move the number slightly to the left */
        top: -0.1rem; /* Move the number up slightly */
        font-size: 8px; /* Make the number smaller */
    }
}