.FadingTextAnimation {
    .fading-text {
        &.visible {
            animation: fadeIn 1s ease-in-out forwards;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

}