/*______________________________________________________________________
     * Supplement Colours
     * ______________________________________________________________________*/
// colours
$supplement-colours: (
    vitamin: (default: #E96F86,
        gradientStart: #AE4075,
        gradientEnd: #D982AC),
    mineral: (default: #FF8110,
        gradientStart: #EF8CBC,
        gradientEnd: #E082B0),
    aminoAcid: (default: #F79A42,
        gradientStart: #7B337D,
        gradientEnd: #AF6DB0),
    plantFamily: (default: #FDC877,
        gradientStart: #E0A4EA,
        gradientEnd: #B977BB),
    fattyAcid: (default: #9ba5db,
        gradientStart: #87A1DD,
        gradientEnd: #98A3D6),
);

.SupplementTable {

    // loop over each type (vitamin, mineral, etc.) in supplement-colours map
    @each $name,
    $colour in $supplement-colours {

        // create background and hover classes for each coloured grid item
        .bg-#{$name} {
            background-color: map-get($colour, default);

            &:hover {
                background-color: lighten(map-get($colour, default), 5%);
            }
        }
    }
}


/*______________________________________________________________________
 * Supplement dialog. Dialogs are rendered outside of the current component,
 so styles cannot be scoped to this component only
 * ______________________________________________________________________*/

.MuiDialog-Supplement {
    .MuiPaper-root {
        margin: 0;
        width: 90vw;
        max-height: 65vh;
        padding: 1rem 0 2rem 0;
        border-radius: 2rem;
        background-image: none;
        @apply bg-primary;

        // custom styles on larger screens
        @screen md {
            width: 100%;
            max-width: 30rem;
            border-radius: 3rem;
            padding: 1rem 1rem 2rem 1rem;
        }
    }
}