.YasuAnimation {
    // rgba with alpha 0.4 is equivalent to 60% brightness
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../../../../assets/images/yasu_start_4k.png');
    background-size: cover;
    background-position: center;


    // we can't access the video element from ReactPlayer through the component classes
    // so we must target it directly with CSS
    video {
        object-fit: cover;
        filter: brightness(60%);
    }
}

@media (max-width: 768px) {
    .YasuAnimation {
        background-position: 35% center;
    }

    .YasuAnimation video {
        object-position: 35% center;
    }

}