@tailwind base;
@tailwind components;
@tailwind utilities;

// CUSTOM FONTS
// Poppins also configured in MUI theme as default font
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../src/assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Quattrocento";
  src: local("Quattrocento"),
    url(../src/assets/fonts/Quattrocento/Quattrocento-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "MuseoModerno";
  src: local("MuseoModerno"),
    url(../src/assets/fonts/MuseoModerno/static/MuseoModerno-Regular.ttf)
      format("truetype");
}

html {
  scrollbar-gutter: stable both-edges;
}

// MUI + Tailwind fix for broken scroll lock when defining custom container for modal-based items
// targets body if it has the "custom" container and the Modal root class in it and hides overflow
body:has(#root .MuiModal-root) {
  
  overflow: hidden;
}

// custom animations
.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.fade-in {
  animation: fadeIn 2s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
