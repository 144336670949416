// disable scrolling thus hiding all page content
// when animation section is showing
body:has(#root #YasuAnimation) {
  overflow: hidden;

  // if animation is playing make sure navbar is
  // fixed at top of screen. if user scrolled before
  // reloading they may not be positioned at top of screen
  // so we want to force navbar to be at top while animation plays
  .NavbarCustom {
    position: fixed;
  }
}

.Explore-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../../../assets/images/YasuDark1.png");
  background-size: cover;
  background-position: center;
}
